import React, { useState, useEffect, useRef } from "react";


// import './Companies.css';

function debounce(func, delay) { // Delays execution of func by delay milliseconds.
  let timerId;
  return (...args) => {
    if (timerId) clearTimeout(timerId);
    timerId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

const fetchWithRetry = async (url, retries = 0, maxRetries = 5) => { // Fetches data with retries and exponential backoff on failure.
  try {
      const response = await fetch(url);
      if (!response.ok) {
          console.log(`response was NOT OK!!!!!!!!!!!!!!`)
          if (response.status === 429 && retries < maxRetries) {
              console.log(`B U T   response.status === 429 && retries < maxRetries is TRUE!!!!!!!!!!!!!!!!!!!!`)
              const delay = Math.pow(2, retries) * 1000; // Exponential backoff
              await new Promise(resolve => setTimeout(resolve, delay));
              return fetchWithRetry(url, retries + 1);
          }
          throw new Error('Network response was not ok');
      }
      return response.json();
  } catch (error) {
      if (retries < maxRetries) {
          const delay = Math.pow(2, retries) * 1000; // Exponential backoff
          await new Promise(resolve => setTimeout(resolve, delay));
          return fetchWithRetry(url, retries + 1);
      }
      throw error; // Re-throw the error if max retries are reached
  }
};

const calculateYPosition = (price, maxPrice, minPrice, chartHeight) => { // Calculates a y-coordinate for a chart based on price.
  const priceRange = maxPrice - minPrice;
  const relativePrice = price - minPrice;
  const yPosition = (relativePrice / priceRange) * chartHeight;
  return chartHeight - yPosition; // Reverse the y-axis direction
};


const createPolylinePoints = (priceHistory, chartWidth, chartHeight) => { // Generates polyline points for a chart based on data.
  const prices = Object.values(priceHistory);
  const maxPrice = Math.max(...prices);
  const minPrice = Math.min(...prices);
  const priceCount = prices.length;
  const pointInterval = chartWidth / (priceCount - 1);
  let polylinePoints = "";

  prices.forEach((price, index) => {
    const x = chartWidth - index * pointInterval;
    // console.log(`index: ${index} `);
    const y = calculateYPosition(price, maxPrice, minPrice, chartHeight);
    polylinePoints += `${x},${y} `;
    // console.log(`polylinePoints: ${polylinePoints} `);
  });

  return polylinePoints.trim();
};

const getGoogleFinanceUrl = (ticker, exchange) => { // Constructs a Google Finance URL for a company.
  let exchangePart;
  switch (exchange) {
    case 'NASDAQ':
      exchangePart = 'NASDAQ';
      break;
    case 'NYSE':
      exchangePart = 'NYSE';
      break;
    case 'NYSE MKT':
      exchangePart = 'NYSEAMERICAN';
      break;
    // case 'NYSE ARCA':
    //   exchangePart = 'NYSEARCA';
    //   break;
    default:
      return null; // Return null if the exchange is not recognized
  }
  return `https://www.google.com/finance/quote/${ticker}:${exchangePart}?window=MAX`;
};




function Companies() { // Main React component for rendering the companies list.

  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);
  const loadingRef = useRef(false);

  const [clickedCompanies, setClickedCompanies] = useState([]);
  const containerRef = useRef(null);

  const handleChartClick = (company) => { // Opens a Google Finance link for the clicked company.
    if (!clickedCompanies.includes(company.ticker)) {
      setClickedCompanies(prevState => [...prevState, company.ticker]);
    }
    window.open(
      getGoogleFinanceUrl(company.ticker, company.exchange), 
      "_blank", 
      `toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=${window.innerWidth},height=${window.innerHeight}`
    );
  };

    const companiesRef = useRef(companies);
    useEffect(() => {
        // console.log("companies useEffect ran");  // Point 2: To check when this useEffect runs
        companiesRef.current = companies;
    }, [companies]);

    const [hasMoreCompanies, setHasMoreCompanies] = useState(true);



    // const maxRetries = 5;


    const [chartDimensions, setChartDimensions] = useState({ width: 100, height: 70 });
    const updateChartDimensions = () => { // Adjusts chart dimensions based on window width.
      let newWidth = 90;  // default width
      let newHeight = 60; // default height
    
      const windowWidth = window.innerWidth;
    
      if (windowWidth <= 380) {
        newWidth = 90;  // adjust as needed
        newHeight = 60; // adjust as needed
      } else if (windowWidth <= 480) {
      // if (windowWidth <= 480) {
        // Smartphone screen dimensions
        newWidth = 90;  // adjust as needed
        newHeight = 60; // adjust as needed
      } else if (windowWidth <= 768) {
        // Tablet screen dimensions
        newWidth = 90;  // adjust as needed
        newHeight = 60; // adjust as needed
      }
    
    // 4th min_width
    // 3rd min_width
    // 2nd min_width
    // min_width windowWidth >= 268
    
      setChartDimensions({ width: newWidth, height: newHeight });
    }

    
    const fetchMoreCompanies = async () => { // Loads more companies and appends them to the list.
        console.log(`got in fetchMoreCompanies`);
    
        if (loadingRef.current) {
            return; // If already loading, just return
        }

        const startTime = new Date(); // Start time

        try {
            loadingRef.current = true;
            const backendURL = process.env.REACT_APP_BACKEND_URL;
            const data = await fetchWithRetry(`${backendURL}/api/companies?offset=${companiesRef.current.length}`);
    
            if (data && data.companies) {
                console.log("Raw companies from API:", data.companies.length);
    
                const newCompanies = data.companies.filter(comp => !companies.some(existingComp => existingComp.ticker === comp.ticker));
    
                setCompanies(prevCompanies => [...prevCompanies, ...newCompanies]);
    
                if (data.companies.length === 0 || newCompanies.length === 0) {
                    setHasMoreCompanies(false);
                }
            } else {
                console.error("Unexpected response format");
            }
    
        } catch (error) {
            console.error('Fetch error:', error);
            // Optionally update error state here
        } finally {
            loadingRef.current = false;

            const endTime = new Date(); // End time
            const duration = endTime - startTime; // Duration in milliseconds
            console.log(`Loading duration: ${duration} ms`); // Log the duration
        }
    };

  
  const [isLoading, setIsLoading] = useState(false);



  const handleScroll = () => { // Checks if user scrolled near the bottom to load more.
    console.log("    CALLED?????")
    // if (!containerRef.current || companies.length === 0) return;
    if (!containerRef.current) return;

    console.log("    CALLED  2  ?????")
    const lastCompanyElement = containerRef.current.lastElementChild;
    if (!lastCompanyElement) return;
    console.log("    lastCompanyElement -> ", lastCompanyElement)
    console.log("    containerRef.current.scrollTop -> ", containerRef.current.scrollTop)
    console.log("    lastCompanyElement.offsetHeight -> ", lastCompanyElement.offsetHeight)
    console.log("    lastCompanyElement.offsetTop -> ", lastCompanyElement.offsetTop)

    const lastBatchHeight = lastCompanyElement.offsetTop + lastCompanyElement.offsetHeight - containerRef.current.scrollTop;
    console.log("    lastBatchHeight -> ", lastBatchHeight)
    const scrollThreshold = lastBatchHeight / 4;
    console.log("")
    console.log("containerRef.current.scrollHeight -> ", containerRef.current.scrollHeight)
    console.log("scrollThreshold -> ", scrollThreshold)
    console.log("")
    const isNearEnd = containerRef.current.scrollTop + containerRef.current.offsetHeight >= containerRef.current.scrollHeight - scrollThreshold;

    if (isNearEnd && !loadingRef.current) {
      fetchMoreCompanies();
    }
  };

  const debouncedHandleScroll = debounce(handleScroll, 300); // Debounced version of handleScroll to limit calls.

  useEffect(() => {
    // console.log("containerRef useEffect ran");  // Point 2: To check when this useEffect runs
    if (containerRef.current) {
      console.log()
      containerRef.current.addEventListener("scroll", debouncedHandleScroll);
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", debouncedHandleScroll);
      }
    };
  }, [containerRef.current]);



  useEffect(() => {
    console.log("Initial fetch useEffect ran");  // Point 2: To check when this useEffect runs
    
    const startTime = new Date(); // Start time

    const fetchCompanies = async () => {
      try {
        // const response = await fetch("https://getnetincome-backend.herokuapp.com/api/companies");
        const backendURL = process.env.REACT_APP_BACKEND_URL;
        const response = await fetch(`${backendURL}/api/companies`);
        // console.log(`Initial fetch companies.length -> ${companiesRef.current.length}`);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();

        // console.log(`Before setting companies from initial fetch: ${companiesRef.current.length}`);
        setCompanies(data.companies);
        // console.log(`After setting companies from initial fetch: ${companiesRef.current.length}`);
      
        const endTime = new Date(); // End time
        const duration = endTime - startTime; // Duration in milliseconds
        console.log(`Initial fetch duration: ${duration} ms`);

      } catch (error) {
        console.error("An error occurred while fetching companies:", error);
        setError("Failed to fetch companies. Please try again later.");
      
        const endTime = new Date();
        const duration = endTime - startTime;
        console.log(`Initial fetch duration with error: ${duration} ms`);
      }
    };
    fetchCompanies();
  }, []);

  








  useEffect(() => {
    updateChartDimensions();
    window.addEventListener('resize', updateChartDimensions);
  
    return () => {
      window.removeEventListener('resize', updateChartDimensions);
    }
  }, []);

  useEffect(() => {
    fetchMoreCompanies(); // Initial load
  }, []);
  
  useEffect(() => {
    if (!loadingRef.current && hasMoreCompanies) {
      fetchMoreCompanies(); // Trigger the next fetch after render
    }
  }, [companies]); // Runs after companies state updates


    
  
  return (
    
    <div className="container" ref={containerRef}>
      {/* {error && <p>{error}</p>} */}
      {companies.map((company, index) => {
        // console.log(`                   Rendering company with ticker: ${company.ticker}`);
        // console.log("                                         company: ", JSON.stringify(company, null, 2));
        // console.log(company);
        return (
          <div className="company-info">
            {/* <p className="company-title">{company.title} ({company.ticker}) {company.exchange} </p> */}
            <p className="company-title">{company.title} ({company.ticker}) </p>

            <div className="wrapper-div">

              {/* <div className="number_of_years-container"> */}
              <div className="container-1">
                {/* <p id="number_of_years">{company.number_of_years} <span className = "yrs">yrs</span></p> */}
                <p className="number_of_years">{company.number_of_years} <span className = "yrs">yrs</span></p>
                {/* <p className="number_of_years-caption">fiscal years for net income/loss calculation</p> */}
                <p className="number_of_years-caption">retrieved for net income/loss calculation</p>
              </div>

              {/* <div className="cumulative_growth_occurrence_rate-container"> */}
              <div className="container-2">
                {/* <p id="cumulative_growth_occurrence_rate">{Math.floor(company.cumulative_growth_occurrence_rate * 100)}%</p> */}
                <p className="cumulative_growth_occurrence_rate">{Math.floor(company.cumulative_growth_occurrence_rate * 100)}%</p>
                {/* <p className="cumulative_growth_occurrence_rate-caption">winning year occurence rate for net income/loss</p> */}
                <p className="cumulative_growth_occurrence_rate-caption">wins against prev years in net income/loss</p>
              </div>

              {/* <div className="chart-container"> */}
              {/* <div className="netincome-chart-container"> */}
              <div className="container-3">
                  <svg className="chart">
                      <polyline
                          fill="none"
                          stroke="blue"
                          strokeWidth="1"
                          points={createPolylinePoints(company.dict_net_income_history, chartDimensions.width, chartDimensions.height)}
                      />
                  </svg>
                  {/* <p className="chart-title">yearly net income/loss chart</p> */}
                  <p className="chart-title">yearly net income/loss</p>
              </div>

              {/* <div className="chart-container"> */}
              {/* <div className="price-chart-container"> */}
              <div className="container-4">
                <a href="#" onClick={(event) => {
                  event.preventDefault();
                  handleChartClick(company); 
                  return false;
                }}>
                  <svg className="chart" style={{stroke: clickedCompanies.includes(company.ticker) ? 'DarkMagenta' : 'blue'}}>
                    <polyline
                      className="polylines"
                      fill="none"
                      strokeWidth="1"
                      points={createPolylinePoints(company.price_history_close, chartDimensions.width, chartDimensions.height)}
                    />
                  </svg>
                </a>
                {/* <div className="chart-title">monthly price chart</div> */}
                <div className="chart-title">monthly price</div>
              </div>

              {/* <div className="dividend_yield-container"> */}
              <div className="container-5">
                {/* <p id="dividend_yield"> */}
                <p className="dividend_yield">
                  {company.dividend_yield !== undefined && company.dividend_yield !== null ? (company.dividend_yield * 100).toFixed(2) + '%' : 'N/A'}
                </p>
                {/* <p id="dividend_yield-caption">dividend yield</p> */}
                <p className="dividend_yield-caption">dividend yield</p>
              </div>
            </div>
          </div>
        );
      })}
      
      {/* {companies.length > 0 && <div className="loading-indicator">Loading...</div>} */}
      {hasMoreCompanies && companies.length > 0 && <div className="loading-indicator">Loading...</div>}

    </div>

  );
}

export default Companies;


